import { Auth } from "aws-amplify";
import constants from "../data/Constants";

export default class DynamoDBService {
  async getCredentials() {
    return new Promise((resolve, reject) => {
      try {
        console.log("in getCredentials()");
        var promise = AWS.config.credentials.getPromise();
        promise.then(
          function() {
            console.log("result...");
            var creds = {
              accessKeyId: AWS.config.credentials.accessKeyId,
              secretAccessKey: AWS.config.credentials.secretAccessKey,
              sessionToken: AWS.config.credentials.sessionToken,
            };
            console.log("got credentials: " + JSON.stringify(creds, null, 2));
            resolve(creds);
          },
          function(err) {
            console.log("err..." + JSON.stringify(err, null, 2));
            resolve(null);
          }
        );
      } catch (error) {
        reject(error);
      }
    });
  }
  async addItem(paramsData) {
    return new Promise((resolve, reject) => {
      try {
        console.log("in addItem()");
        var that = this;
        // adds given item
        var docClient = new AWS.DynamoDB.DocumentClient({
          apiVersion: "2012-08-10",
        });
        var params = paramsData;
        if (this.credentials) {
          console.log("in addItem(), have credentials, adding item...");
          docClient.put(params, function(err, data) {
            if (err) {
              console.log("Error adding item", JSON.stringify(err, null, 2));
              reject(err);
            } else {
              console.log("Success adding item", JSON.stringify(data, null, 2));
              resolve(JSON.stringify(data, null, 2));
            }
          });
        } else {
          console.log("in addItem(), credentials null, getting credentials...");
          var that = this;
          var promise = AWS.config.credentials.getPromise();
          promise.then(
            function() {
              console.log("in addItem(), got credentials, adding item...");
              var creds = {
                accessKeyId: AWS.config.credentials.accessKeyId,
                secretAccessKey: AWS.config.credentials.secretAccessKey,
                sessionToken: AWS.config.credentials.sessionToken,
              };
              that.credentials = creds;
              docClient.put(params, function(err, data) {
                if (err) {
                  console.log(
                    "Error adding item",
                    JSON.stringify(err, null, 2)
                  );
                  reject(err);
                } else {
                  console.log(
                    "Success adding item",
                    JSON.stringify(data, null, 2)
                  );
                  resolve(JSON.stringify(data, null, 2));
                }
              });
            },
            function(err) {
              console.log(
                "err getting credentials..." + JSON.stringify(err, null, 2)
              );
              that.credentials = null;
              reject(err);
            }
          );
        }
      } catch (error) {
        alert(error);
      }
    });
  }
  async deleteItem(paramsData) {
    return new Promise((resolve, reject) => {
      try {
        // deletes item for given key
        console.log("in deleteItem()");
        var that = this;
        var docClient = new AWS.DynamoDB.DocumentClient({
          apiVersion: "2012-08-10",
        });
        var params = paramsData;
        if (this.credentials) {
          console.log("in deleteItem(), got credentials....");
          docClient.delete(params, function(err, data) {
            if (err) {
              console.log(
                "error deleting item, err: " + JSON.stringify(err, null, 2)
              );
              reject(err);
            } else {
              console.log(
                "success deleting item: " + JSON.stringify(data, null, 2)
              );
              resolve(JSON.stringify(data, null, 2));
            }
          });
        } else {
          console.log("in deleteItem(), no credentials....getting credentials");
          var that = this;
          var promise = AWS.config.credentials.getPromise();
          promise.then(
            function() {
              console.log("in deleteItem(), got credentials, deleting item...");
              var creds = {
                accessKeyId: AWS.config.credentials.accessKeyId,
                secretAccessKey: AWS.config.credentials.secretAccessKey,
                sessionToken: AWS.config.credentials.sessionToken,
              };
              that.credentials = creds;
              docClient.delete(params, function(err, data) {
                if (err) {
                  console.log(
                    "error deleting item, err: " + JSON.stringify(err, null, 2)
                  );
                  reject(err);
                } else {
                  console.log(
                    "success deleting item: " + JSON.stringify(data, null, 2)
                  );
                  resolve(JSON.stringify(data, null, 2));
                }
              });
            },
            function(err) {
              console.log(
                "err getting credentials..." + JSON.stringify(err, null, 2)
              );
              that.credentials = null;
              reject(err);
            }
          );
        }
      } catch (error) {
        alert(error);
      }
    });
  }
  async updateItem(paramsData) {
    return new Promise((resolve, reject) => {
      try {
        // updates (ovewrites) existing item, adds item if not exist, returns updated attributes;
        console.log("in updateItem()");
        var that = this;
        var docClient = new AWS.DynamoDB.DocumentClient({
          apiVersion: "2012-08-10",
        });
        var params = paramsData;
        if (this.credentials) {
          console.log("in updateItem(), got credentials....");
          docClient.update(params, function(err, data) {
            if (err) {
              console.log(
                "error updating item, err: " + JSON.stringify(err, null, 2)
              );
              reject(err);
            } else {
              console.log(
                "success updating item: " + JSON.stringify(data, null, 2)
              );
              resolve(JSON.stringify(data, null, 2));
            }
          });
        } else {
          console.log("in updateItem(), no credentials....getting credentials");
          var that = this;
          var promise = AWS.config.credentials.getPromise();
          promise.then(
            function() {
              console.log("in updateItem(), got credentials, updating item...");
              var creds = {
                accessKeyId: AWS.config.credentials.accessKeyId,
                secretAccessKey: AWS.config.credentials.secretAccessKey,
                sessionToken: AWS.config.credentials.sessionToken,
              };
              that.credentials = creds;
              docClient.update(params, function(err, data) {
                if (err) {
                  console.log(
                    "error updating item, err: " + JSON.stringify(err, null, 2)
                  );
                  reject(err);
                } else {
                  console.log(
                    "success updating item: " + JSON.stringify(data, null, 2)
                  );
                  resolve(JSON.stringify(data, null, 2));
                }
              });
            },
            function(err) {
              console.log(
                "err getting credentials..." + JSON.stringify(err, null, 2)
              );
              that.credentials = null;
              reject(err);
            }
          );
        }
      } catch (error) {
        alert(error);
      }
    });
  }
  async getAllItems(paramsData) {
    return new Promise((resolve, reject) => {
      try {
            console.log("in getItem()");
            var docClient = new AWS.DynamoDB.DocumentClient({
              apiVersion: "2012-08-10",
            });
            var params = paramsData;
            if (this.credentials) {
              var that = this;
              console.log("in getItem(), got credentials....");
              docClient.scan(params, function(err, data) {
                if (err) {
                  console.log(
                    "error getting item, err: " + JSON.stringify(err, null, 2)
                  );
                  reject(err);
                } else {
                  console.log(
                    "success getting item: " + JSON.stringify(data, null, 2)
                  );
                  if (data == null) {
                    console.log("data is null");
                  } else {
                    console.log("data is not null");
                    
                  }
                  resolve(data);
                }
              });
            } else {
              console.log("in getItem(), no credentials....getting credentials");
              var that = this;
              var promise = AWS.config.credentials.getPromise();
              promise.then(
                function() {
                  console.log("in addItem(), got credentials, getting item...");
      
                  var creds = {
                    accessKeyId: AWS.config.credentials.accessKeyId,
                    secretAccessKey: AWS.config.credentials.secretAccessKey,
                    sessionToken: AWS.config.credentials.sessionToken,
                  };
                  that.credentials = creds;
                  docClient.scan(params, function(err, data) {
                    if (err) {
                      console.log(err);
                      reject(err);
                    } else {
                      console.log(data);
                      resolve(data);
                    }
                  });
                },
                function(err) {
                  console.log(
                    "err getting credentials..." + JSON.stringify(err, null, 2)
                  );
                  that.credentials = null;
                  reject(err);
                }
              );
            }
      } catch (error) {
        alert(error);
      }
    });
  }
  async getItem(paramsData) {
    return new Promise((resolve, reject) => {
      try {
            console.log("in getItem()");
            var docClient = new AWS.DynamoDB.DocumentClient({
              apiVersion: "2012-08-10",
            });
            var params = paramsData;
            if (this.credentials) {
              var that = this;
              console.log("in getItem(), got credentials....");
              docClient.get(params, function(err, data) {
                if (err) {
                  console.log(
                    "error getting item, err: " + JSON.stringify(err, null, 2)
                  );
                  reject(err);
                } else {
                  console.log(
                    "success getting item: " + JSON.stringify(data, null, 2)
                  );
                  if (data == null) {
                    console.log("data is null");
                  } else {
                    console.log("data is not null");
                    
                  }
                  resolve(data);
                }
              });
            } else {
              console.log("in getItem(), no credentials....getting credentials");
              var that = this;
              var promise = AWS.config.credentials.getPromise();
              promise.then(
                function() {
                  console.log("in addItem(), got credentials, getting item...");
      
                  var creds = {
                    accessKeyId: AWS.config.credentials.accessKeyId,
                    secretAccessKey: AWS.config.credentials.secretAccessKey,
                    sessionToken: AWS.config.credentials.sessionToken,
                  };
                  that.credentials = creds;
                  docClient.get(params, function(err, data) {
                    if (err) {
                      console.log(err);
                      reject(err);
                    } else {
                      console.log(data);
                      resolve(data);
                    }
                  });
                },
                function(err) {
                  console.log(
                    "err getting credentials..." + JSON.stringify(err, null, 2)
                  );
                  that.credentials = null;
                  reject(err);
                }
              );
            }
      } catch (error) {
        alert(error);
      }
    });
  }
  async uploadToS3(paramsData) {
    return new Promise((resolve, reject) => {
      try {
            
            var params = paramsData;
            
              //console.log("in uploadToS3(), no credentials....getting credentials");
              var that = this;
              // var promise = AWS.config.credentials.getPromise();
              // promise.then(
              //   function() {
              //     console.log("in uploadToS3(), got credentials, uploadToS3 ...");
      
                  // var creds = {
                  //   accessKeyId: AWS.config.credentials.accessKeyId,
                  //   secretAccessKey: AWS.config.credentials.secretAccessKey,
                  //   sessionToken: AWS.config.credentials.sessionToken,
                  // };
                  // that.credentials = creds;
                  var s3bucket = new AWS.S3({
                    accessKeyId: constants.S3_ACCESS_KEY_ID,
                    secretAccessKey: constants.S3_SECRET_ACCESS_KEY,
                    Bucket: constants.S3_BUCKET_NAME,
                    region: constants.AWS_CONFIG_REGION,
                    ACL:'public-read'
                  });
                  s3bucket.upload(params, function (err, data) {
                    if (err) {
                      console.log('error in callback');
                      console.log(err);
                      reject(err);
                    }
                    console.log('success');
                    console.log(data);
                    resolve(data.Location);
                    // const params = {
                    //   Bucket: constants.S3_BUCKET_NAME,
                    //   Key: paramsData.Key, 
                    //   //Expires: 60
                    // }
                    // s3bucket.getSignedUrl('getObject', params, function (err, url) {
                    //     if (err) {
                    //       reject(err)
                    //     }
                    //     resolve(url)
                    //   })
                    //resolve(data);
                  });
                // },
                // function(err) {
                //   console.log(
                //     "err getting credentials..." + JSON.stringify(err, null, 2)
                //   );
                //   that.credentials = null;
                //   reject(err);
                // }
             // );
            
      } catch (error) {
        alert(error);
      }
    });
  }
}
