<template>
  <CRow>
    <CCol col="9">
      <CCard>
        <CCardHeader style="font-weight:bold;">
          {{ siteData.site_name }}
          <div class="float-right" style="color:#ff0000;" @click="removeSite" v-if="permissionsData.edit_site">
            <CIcon name="cil-trash" size="lg" />
          </div>
        </CCardHeader>
        <CCardBody>
          <h5>Basic Info</h5>
          <hr />
          <CInput
            label="Name"
            placeholder="Enter site name"
            v-model="siteData.site_name"
          />
          <CInput
            label="Phone"
            placeholder="Enter phone"
            v-model="siteData.site_phone"
          />

          <div><label>Enabled?</label></div>
          <CSwitch
            class="mr-1"
            color="success"
            shape="pill"
            :checked.sync="siteData.site_enabled"
          />

          <h5>Location Info</h5>
          <hr />
          <CInput
            label="Address"
            placeholder="Enter address"
            v-model="siteData.site_address"
          />
          <CRow>
            <CCol>
              <CSelect
                label="Select A Building"
                placeholder="-- Please Select --"
                :options="siteBuildings"
                :value.sync="siteData.site_building"
              >
                <!-- [
                  { value: '', label: '-- Please Select --' },
                  { value: 'Add A New Building', label: 'Add A New Building' },
                ] -->
              </CSelect>
            </CCol>
            <CCol v-if="siteData.site_building == 'Add A New Building'">
              <CInput
                label="Or Add A New Building"
                placeholder="Enter building"
                v-model="siteData.site_NEWbuilding"
              />
            </CCol>
          </CRow>
          <CInput
            label="Location"
            placeholder="Enter location"
            v-model="siteData.site_location"
          />
          <CRow>
            <CCol sm="6">
              <CInput
                label="City"
                placeholder="Enter city"
                v-model="siteData.site_city"
              />
            </CCol>
            <CCol sm="6">
              <CSelect
                label="State"
                placeholder="Please select"
                :options="states"
                :value.sync="siteData.site_state"
              >
              </CSelect>
            </CCol>
          </CRow>
          <CRow>
            <CCol sm="4">
              <h5>Financial Info</h5>
              <hr />
              <CInput
                label="Delivery Fee"
                v-model="siteData.site_cf_percent"
                prepend="$"
                :readonly="!permissionsData.edit_delivery_fee"
              />
              <CInput
                label="Jogg Commission"
                v-model="siteData.site_jogg_commission_percent"
                append="%"
                :readonly="!permissionsData.edit_jogg_commission"
              />
              <CInput
                label="Discount"
                v-model="siteData.site_discount_percent"
                append="%"
                :readonly="!permissionsData.edit_discount_sales_tax"
              />
              <CInput
                label="Sales Tax"
                v-model="siteData.site_tax_percent"
                append="%"
                :readonly="!permissionsData.edit_discount_sales_tax"
              />
            </CCol>
            <CCol sm="8" class="text-center">
              <h5>Business Hours</h5>
              <hr />
              <CRow>
                <CCol tag="label" sm="3" class="col-form-label">
                  Day
                </CCol>
                <CCol tag="label" sm="3" class="col-form-label">
                  Open?
                </CCol>
                <CCol tag="label" sm="3" class="col-form-label">
                  Opens
                </CCol>
                <CCol tag="label" sm="3" class="col-form-label">
                  Closes
                </CCol>
              </CRow>

              <CRow
                v-for="day in business_hours"
                v-bind:key="day.dayOfWeek"
                class="business-hours"
              >
                <CCol sm="3" class="mt-2">
                  {{ getDayOfWeek(day.dayOfWeek) }}
                </CCol>
                <CCol sm="3" class="mt-1">
                  <CSwitch
                    class="mr-1"
                    color="success"
                    shape="pill"
                    :checked.sync="day.isOpen"
                  />
                </CCol>
                <CCol sm="3" :class="{ 'd-none': !day.isOpen }">
                  <vue-timepicker
                    v-model="day.opens"
                    auto-scroll
                    input-width="100px"
                    format="h:mma"
                    :minute-interval="15"
                  ></vue-timepicker>
                </CCol>
                <CCol sm="3" :class="{ 'd-none': !day.isOpen }">
                  <vue-timepicker
                    v-model="day.closes"
                    auto-scroll
                    input-width="100px"
                    format="h:mma"
                    :minute-interval="15"
                  ></vue-timepicker>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
        <CCardFooter>
          <CRow>
            <CCol sm="6"
              ><CButton color="light" @click="goBack">Cancel</CButton></CCol
            >
            <CCol sm="6" class="text-right"
              ><CButton color="primary" @click="onSaveEditClick" v-if="permissionsData.edit_site"
                >Save</CButton
              ></CCol
            >
          </CRow>
        </CCardFooter>
      </CCard>
    </CCol>
    <CCol col="3">
      <CImg :src="siteData.site_logo" fluid thumbnail v-if="!isLogoEdited" />
      <CImg :src="imageData" fluid thumbnail v-if="isLogoEdited" />
      <label>Upload A Logo</label>
      <CInputFile horizontal custom @change="onFileSelected" />
    </CCol>
  </CRow>
</template>

<script>
import constants from "../../data/Constants.js";
import usStates from "../../data/States.js";
import DynamoDBService from "../../services/dynamodbservice.js";
import Amplify, { Storage } from "aws-amplify";
import store from "../../store.js";
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
export default {
  name: "Site",
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes("sites");
    });
  },
  data() {
    return {
      permissionsData:{},
      siteBuildings: [],
      sitesData: [],
      isLogoEdited: false,
      imageData: "",
      fileSelected: null,
      isEditMode: false,
      siteData: {
        site_id: "",
        site_name: "",
        site_phone: "",

        site_address: "",
        site_building: "",
        site_location: "",
        site_city: "",
        site_state: "",

        site_cf_percent: "", //delivery fee
        site_jogg_commission_percent: "",
        site_discount_percent: "",
        site_tax_percent: "",

        site_logo: "img/upload_site_logo.png",
        site_enabled: "true",
      },
      business_hours: [
        { dayOfWeek: "0", isOpen: "true", opens: "9:00am", closes: "10:00pm" },
        { dayOfWeek: "1", isOpen: "true", opens: "9:00am", closes: "10:00pm" },
        { dayOfWeek: "2", isOpen: "true", opens: "9:00am", closes: "10:00pm" },
        { dayOfWeek: "3", isOpen: "true", opens: "9:00am", closes: "10:00pm" },
        { dayOfWeek: "4", isOpen: "true", opens: "9:00am", closes: "10:00pm" },
        { dayOfWeek: "5", isOpen: "true", opens: "9:00am", closes: "10:00pm" },
        { dayOfWeek: "6", isOpen: "true", opens: "9:00am", closes: "10:00pm" },
      ],
      sitesOpened: null,
      states: usStates,
    };
  },
  components: {
    VueTimepicker,
  },
  mounted: function() {
    this.permissionsData = store.state.permissions;
    this.getSiteItems();
  },
  computed: {
    fields() {
      return [
        { key: "key", label: this.username, _style: "width:150px" },
        { key: "value", label: "", _style: "width:150px;" },
      ];
    },
  },
  methods: {
    getSiteItems() {
      var that = this;
      var params = {
        TableName: constants.SITES_TABLE_NAME,
      };
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.getAllItems(params).then(
        (data) => {
          if (data != undefined || data != null || data.Count > 0) {
            that.sitesData = [];
            that.sitesData = data.Items;

            const key = "site_building";
            const arrayUniqueByKey = [
              ...new Map(
                that.sitesData.map((item) => [item[key], item])
              ).values(),
            ];

            that.siteBuildings = [];
            var temp1 = { value: "Please Select", text: "-- Please Select --" };
            var temp2 = {
              value: "Add A New Building",
              text: "Add A New Building",
            };
            that.siteBuildings.push(temp1);
            that.siteBuildings.push(temp2);
            arrayUniqueByKey.forEach((element) => {
              var temp = { value: "", text: "" };
              temp.value = element.site_building;
              temp.text = element.site_building;
              that.siteBuildings.push(temp);
            });

            var test = 0;
          }
          this.assignSiteData();
        },
        (error) => {
          console.log(error);
        }
      );
    },
    onFileSelected(event) {
      this.isLogoEdited = true;
      this.fileSelected = event[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        this.imageData = e.target.result;
      };
      reader.readAsDataURL(this.fileSelected);
      //this.uploadToS3(this.fileSelected)
    },
    async uploadToS3(file) {
      var params = {
        Bucket: constants.S3_BUCKET_NAME,
        Key: this.siteData.site_id + ".png",
        Body: file,
      };

      const dynamoDBService = new DynamoDBService();
      dynamoDBService.uploadToS3(params).then(
        (data) => {
          console.log(data);
          this.siteData.site_logo = data;
          this.onEditData();
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    },
    goBack() {
      this.sitesOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/sites" });
    },
    getDayOfWeek(dayIndex) {
      return ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][dayIndex] || "";
    },
    assignSiteData() {
      if (this.$route.query.selectedData != undefined) {
        this.isEditMode = true;
        this.siteData = this.$route.query.selectedData;

        if (
          this.siteData.site_logo == undefined ||
          this.siteData.site_logo == null ||
          this.siteData.site_logo == ""
        ) {
          this.siteData.site_logo = "img/upload_site_logo.png";
        }
        if (this.siteData.site_enabled != undefined) {
          var x = true
          if(this.siteData.site_enabled == "false"){
            x = false;
          }
          this.siteData.site_enabled = x;
        }else{
          this.siteData.site_enabled=true;
        }

        if (this.$route.query.selectedData.business_hours != undefined) {
          this.business_hours = this.$route.query.selectedData.business_hours;
        }
        this.business_hours.forEach((element) => {
           var x = false
          if(element.isOpen == "true"){
            x = true;
          }
          element.isOpen = x;
        });
      } else {
        this.isEditMode = false;
        this.siteData.site_id = "S-" + this.generateUniqueSiteID();
        var test = 0;
      }
    },
    onSaveEditClick() {
      if (this.isValid()) {
        if (this.isEditMode) {
          if (
            this.fileSelected != undefined &&
            this.fileSelected != null &&
            this.fileSelected != ""
          ) {
            this.uploadToS3(this.fileSelected);
          } else {
            this.onEditData();
          }
        } else {
          this.onSaveData();
        }
      }
    },
    generateUniqueSiteID() {
      var uniqueVal = 0;
      uniqueVal = Date.now();
      return String(uniqueVal);
    },
    onSaveData() {
      var newBuilding = "";
      newBuilding = this.siteData.site_building;
      if (this.siteData.site_building == "Add A New Building") {
        newBuilding = this.siteData.site_NEWbuilding;
      }

      this.business_hours.forEach((element) => {
        element.isOpen = element.isOpen.toString();
      });

      var x = this.siteData.site_enabled.toString();
      this.siteData.site_enabled = x;
      var params = {
        TableName: constants.SITES_TABLE_NAME,

        Item: {
          site_id: this.siteData.site_id,
          site_name: this.siteData.site_name,
          site_phone: this.siteData.site_phone,

          site_address: this.siteData.site_address,
          site_building: newBuilding, //this.siteData.site_building,
          site_location: this.siteData.site_location,
          site_city: this.siteData.site_city,
          site_state: this.siteData.site_state,

          site_cf_percent: this.siteData.site_cf_percent, //delivery fee
          site_jogg_commission_percent: this.siteData
            .site_jogg_commission_percent,
          site_discount_percent: this.siteData.site_discount_percent,
          site_tax_percent: this.siteData.site_tax_percent,
          business_hours: this.business_hours,
          site_enabled: this.siteData.site_enabled,
        },
      };
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.addItem(params).then(
        (data) => {
          console.log(data);
          if (this.fileSelected != null) {
            this.uploadToS3(this.fileSelected);
          } else {
            this.$router.push({ path: "/sites" });
          }
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    },
    onEditData() {
      if (
        this.siteData.site_logo == undefined ||
        this.siteData.site_logo == null
      ) {
        this.siteData.site_logo = "";
      }
      var newBuilding = "";
      newBuilding = this.siteData.site_building;
      if (this.siteData.site_building == "Add A New Building") {
        newBuilding = this.siteData.site_NEWbuilding;
      }
      this.business_hours.forEach((element) => {
        element.isOpen = element.isOpen.toString();
        element.dayOfWeek = element.dayOfWeek.toString();
      });
      var x = this.siteData.site_enabled.toString();
      this.siteData.site_enabled = x;
      var params = {
        TableName: constants.SITES_TABLE_NAME,
        Key: { site_id: this.siteData.site_id },

        UpdateExpression: `set #v1 = :site_name_val, 
          #v2 = :site_phone_val, 
          #v3 = :site_address_val, 
          #v4 = :site_building_val,
          #v5 = :site_location_val,
          #v6 = :site_city_val,
          #v7 = :site_state_val,
          #v8 = :site_cf_percent_val,
          #v9 = :site_jogg_commission_percent_val,
          #v10 = :site_discount_percent_val,
          #v11 = :site_tax_percent_val,
          #v12 = :business_hours_val,
          #v13 = :site_logo_val,
          #v14 = :site_enabled_val`,
        ExpressionAttributeNames: {
          "#v1": "site_name",
          "#v2": "site_phone",
          "#v3": "site_address",
          "#v4": "site_building",
          "#v5": "site_location",
          "#v6": "site_city",
          "#v7": "site_state",
          "#v8": "site_cf_percent",
          "#v9": "site_jogg_commission_percent",
          "#v10": "site_discount_percent",
          "#v11": "site_tax_percent",
          "#v12": "business_hours",
          "#v13": "site_logo",
          "#v14": "site_enabled",
        },
        ExpressionAttributeValues: {
          ":site_name_val": this.siteData.site_name,
          ":site_phone_val": this.siteData.site_phone,
          ":site_address_val": this.siteData.site_address,
          ":site_building_val": newBuilding, //this.siteData.site_building,
          ":site_location_val": this.siteData.site_location,
          ":site_city_val": this.siteData.site_city,
          ":site_state_val": this.siteData.site_state,
          ":site_cf_percent_val": this.siteData.site_cf_percent,
          ":site_jogg_commission_percent_val": this.siteData
            .site_jogg_commission_percent,
          ":site_discount_percent_val": this.siteData.site_discount_percent,
          ":site_tax_percent_val": this.siteData.site_tax_percent,
          ":business_hours_val": this.business_hours,
          ":site_logo_val": this.siteData.site_logo,
          ":site_enabled_val": this.siteData.site_enabled,
        },

        ReturnValues: "ALL_NEW",
      };

      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.updateItem(params).then(
        (data) => {
          console.log(data);
          this.$router.push({ path: "/sites" });
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    },
    removeSite() {
      var params = {
        TableName: constants.SITES_TABLE_NAME,
        Key: {
          site_id: this.siteData.site_id,
        },
        ReturnValues: "NONE",
      };
      const dynamoDBService = new DynamoDBService();
      this.credentials = dynamoDBService.getCredentials();
      dynamoDBService.deleteItem(params).then(
        (data) => {
          console.log(data);
          this.$router.push({ path: "/sites" });
        },
        (error) => {
          console.log(error);
          this.$alert(error.message, constants.INFORMATION);
        }
      );
    },
    isValid() {
      var returnFlag = true;
      // if (
      //   this.siteData.site_id == undefined ||
      //   this.siteData.site_id == null ||
      //   this.siteData.site_id == ""
      // ) {
      //   this.$alert(constants.SITE_ID_VALIDATION, constants.INFORMATION);
      //   return (returnFlag = false);
      // }
      if (
        this.siteData.site_name == undefined ||
        this.siteData.site_name == null ||
        this.siteData.site_name == ""
      ) {
        this.$alert(constants.SITE_NAME_VALIDATION, constants.INFORMATION);
        return (returnFlag = false);
      }
      if (
        this.siteData.site_phone == undefined ||
        this.siteData.site_phone == null ||
        this.siteData.site_phone == ""
      ) {
        this.$alert(constants.SITE_PHONE_VALIDATION, constants.INFORMATION);
        return (returnFlag = false);
      }
      if (
        this.siteData.site_address == undefined ||
        this.siteData.site_address == null ||
        this.siteData.site_address == ""
      ) {
        this.$alert(constants.SITE_ADDRESS_VALIDATION, constants.INFORMATION);
        return (returnFlag = false);
      }
      if (
        this.siteData.site_building == undefined ||
        this.siteData.site_building == null ||
        this.siteData.site_building == ""
      ) {
        this.$alert(constants.SITE_BUILDING_VALIDATION, constants.INFORMATION);
        return (returnFlag = false);
      }
      if (this.siteData.site_building == "Add A New Building") {
        if (
          this.siteData.site_NEWbuilding == undefined ||
          this.siteData.site_NEWbuilding == null ||
          this.siteData.site_NEWbuilding == ""
        ) {
          this.$alert(
            constants.SITE_NEW_BUILDING_VALIDATION,
            constants.INFORMATION
          );
          return (returnFlag = false);
        }
      }
      if (
        this.siteData.site_location == undefined ||
        this.siteData.site_location == null ||
        this.siteData.site_location == ""
      ) {
        this.$alert(constants.SITE_LOCATION_VALIDATION, constants.INFORMATION);
        return (returnFlag = false);
      }
      if (
        this.siteData.site_city == undefined ||
        this.siteData.site_city == null ||
        this.siteData.site_city == ""
      ) {
        this.$alert(constants.SITE_CITY_VALIDATION, constants.INFORMATION);
        return (returnFlag = false);
      }
      if (
        this.siteData.site_state == undefined ||
        this.siteData.site_state == null ||
        this.siteData.site_state == ""
      ) {
        this.$alert(constants.SITE_STATE_VALIDATION, constants.INFORMATION);
        return (returnFlag = false);
      }
      if (
        this.siteData.site_cf_percent == undefined ||
        this.siteData.site_cf_percent == null ||
        this.siteData.site_cf_percent == ""
      ) {
        this.$alert(
          constants.SITE_CF_PERCENT_VALIDATION,
          constants.INFORMATION
        );
        return (returnFlag = false);
      }
      if (
        this.siteData.site_jogg_commission_percent == undefined ||
        this.siteData.site_jogg_commission_percent == null ||
        this.siteData.site_jogg_commission_percent == ""
      ) {
        this.$alert(
          constants.SITE_JOGG_COMMISSION_PERCENT_VALIDATION,
          constants.INFORMATION
        );
        return (returnFlag = false);
      }
      if (
        this.siteData.site_discount_percent == undefined ||
        this.siteData.site_discount_percent == null ||
        this.siteData.site_discount_percent == ""
      ) {
        this.$alert(
          constants.SITE_DISCOUNT_PERCENT_VALIDATION,
          constants.INFORMATION
        );
        return (returnFlag = false);
      }
      if (
        this.siteData.site_tax_percent == undefined ||
        this.siteData.site_tax_percent == null ||
        this.siteData.site_tax_percent == ""
      ) {
        this.$alert(
          constants.SITE_TAX_PERCENT_VALIDATION,
          constants.INFORMATION
        );
        return (returnFlag = false);
      }
      return returnFlag;
    },
  },
};
</script>
